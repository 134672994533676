<template>
  <div class="comp-gridDepartment">
    <el-form :inline="true" :model="dataForm">
      <el-form-item>
        <el-input
          v-model="dataForm.keyword"
          placeholder="输入岗位负责人名字或职位搜索"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="dataForm.departmentId"
          placeholder="按部门筛选"
          clearable
          filterable
        >
          <el-option
            v-for="item in departmentList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select
          v-model="dataForm.teamId"
          placeholder="按班组筛选"
          clearable
          filterable
          @focus="teamFocus"
        >
          <el-option
            v-for="item in teamList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button @click="getDataList()">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="dataList"
      border
      size="medium"
      v-loading="dataListLoading"
      style="width: 100%"
    >
      <el-table-column
        type="index"
        header-align="center"
        align="center"
        label="序号"
        width="80"
      >
      </el-table-column>
      <el-table-column
        prop="departmentName"
        header-align="center"
        align="center"
        label="部门"
      >
      </el-table-column>
      <el-table-column
        prop="depatmentChargeName"
        header-align="center"
        align="center"
        label="部门负责人"
      >
      </el-table-column>
      <el-table-column
        prop="teamName"
        header-align="center"
        align="center"
        label="班组"
      >
      </el-table-column>
      <el-table-column
        prop="teamChargeName"
        header-align="center"
        align="center"
        label="班组负责人"
      >
      </el-table-column>
      <el-table-column
        prop="positionChargeName"
        header-align="center"
        align="center"
        label="岗位负责人"
      >
      </el-table-column>
      <el-table-column
        prop="zhize"
        header-align="center"
        align="center"
        label="职位"
      >
      </el-table-column>
      <el-table-column
        prop="yewu"
        header-align="center"
        align="center"
        label="业务"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="quyu"
        header-align="center"
        align="center"
        label="区域"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="shebei"
        header-align="center"
        align="center"
        label="设备"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="effectiveDate"
        header-align="center"
        align="center"
        label="生效时间"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.effectiveDate">{{
            scope.row.effectiveDate.substring(0, 10)
          }}</span>
        </template>
      </el-table-column>

      <el-table-column
        fixed="right"
        header-align="center"
        align="center"
        width="150"
        label="操作"
      >
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            @click="seeDetailHandler(scope.row.id)"
            >查看</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="text-align: center; margin-top: 10px"
      @size-change="sizeChangeHandle"
      @current-change="currentChangeHandle"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 50, 100, 1000, 10000]"
      :page-size="pageSize"
      :total="totalPage"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>

    <see-detail v-if="seeDetail_visible" ref="SeeDetailDom"></see-detail>
  </div>
</template>

<script>
import SeeDetail from "./see-detail.vue";

export default {
  components: {
    SeeDetail,
  },
  data() {
    return {
      seeDetail_visible: false,
      dataForm: {
        keyword: "",
        departmentId: "",
        teamId: "",
      },
      departmentList: [], // 部门列表
      teamList: [], // 班组列表
      dataList: [],
      pageIndex: 1,
      pageSize: 10,
      totalPage: 0,
      dataListLoading: false,
      dataListSelections: [],
    };
  },
  methods: {
    init() {
      this.getDataList();
      this.getDepartmentList();
    },
    // 班组选择框获得焦点
    teamFocus() {
      if (!this.dataForm.departmentId) {
        this.$message.error("请先选择部门");
        return;
      }
      this.getTeamList();
    },
    // 获取班组列表
    getTeamList() {
      this.$http.team
        .list({ limit: 99999, page: 1, did: this.dataForm.departmentId })
        .then((res) => {
          if (res && res.code == 0) {
            this.teamList = res.data.list;
          }
        });
    },
    // 获取部门列表
    getDepartmentList() {
      this.$http.department.list({ limit: 9999, page: 1 }).then((res) => {
        if (res && res.code == 0) {
          this.departmentList = res.data.list;
        }
      });
    },
    // 获取数据列表
    getDataList() {
      this.dataListLoading = true;
      this.dataForm.page = this.pageIndex;
      this.dataForm.limit = this.pageSize;

      this.$http.gridposition.list(this.dataForm).then((data) => {
        if (data && data.code === 0) {
          this.dataList = data.data.list;
          this.totalPage = data.data.totalCount;
        } else {
          this.dataList = [];
          this.totalPage = 0;
        }
        this.dataListLoading = false;
      });
    },
    // 每页数
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageIndex = 1;
      this.getDataList();
    },
    // 当前页
    currentChangeHandle(val) {
      this.pageIndex = val;
      this.getDataList();
    },
    // 查看详情
    seeDetailHandler(id) {
      this.seeDetail_visible = true;
      this.$nextTick(() => {
        this.$refs.SeeDetailDom.init(id);
      });
    },
  },
};
</script>