<template>
  <div class="page-home">
    <div class="home-left">
      <div class="topbar">
        <!-- 当前屏幕实时宽度：{{curClientWidth}} -->
        <div class="topbar-header">
          <img
            class="topbar-header-icon"
            src="@/assets/imgs/home/tabbar-top-icon.png"
          />
          <div class="topbar-title">
            欢迎回来，平安巴士已经无事故运行<span class="title-key">8</span>天了
          </div>
        </div>

        <ul class="topbar-list">
          <li class="topbar-item">
            <div class="item-wrapper person">
              <img
                class="item-icon"
                src="@/assets/imgs/home/topbar-icon1.png"
                alt=""
              />

              <div class="item-content">
                <div class="item-title">员工人数</div>

                <div class="item-data">
                  <span class="data-number">{{
                    dataInfo.countEmployeeNum
                  }}</span>

                  <span class="data-unit">个</span>
                </div>
              </div>
            </div>
          </li>
          <li class="topbar-item">
            <div class="item-wrapper car">
              <img
                class="item-icon"
                src="@/assets/imgs/home/topbar-icon2.png"
                alt=""
              />

              <div class="item-content">
                <div class="item-title">车辆数据</div>

                <div class="item-data">
                  <span class="data-number">{{ dataInfo.countCarNum }}</span>

                  <span class="data-unit">辆</span>
                </div>
              </div>
            </div>
          </li>
          <li class="topbar-item">
            <div class="item-wrapper accident">
              <img
                class="item-icon"
                src="@/assets/imgs/home/topbar-icon3.png"
                alt=""
              />

              <div class="item-content">
                <div class="item-title">事故数据</div>

                <div class="item-data">
                  <span class="data-number">{{
                    dataInfo.countAccidentNum
                  }}</span>

                  <span class="data-unit">条</span>
                </div>
              </div>
            </div>
          </li>
          <li class="topbar-item">
            <div class="item-wrapper certificates">
              <img
                class="item-icon"
                src="@/assets/imgs/home/topbar-icon4.png"
                alt=""
              />

              <div class="item-content">
                <div class="item-title">证件到期</div>

                <div class="item-data">
                  <span class="data-number">{{
                    (dataInfo.zgzygqNum || 0) +
                    (dataInfo.xszygqNum || 0) +
                    (dataInfo.dlyszygqNum || 0)
                  }}</span>

                  <span class="data-unit">个</span>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div class="my-chart__container">
        <div class="my-chart__header">
          <div class="chart-title">
            <!-- 培训教育情况 -->
          </div>

          <ul class="chart-tabs">
            <li
              class="chart-tab"
              v-for="(item, index) in tabList"
              :key="index"
              :class="current_tab == item.val ? 'tab-active' : 'tab-default'"
              @click="clickTab(item)"
            >
              {{ item.label }}
            </li>
          </ul>
        </div>

        <div class="my-chart__body">
          <div class="chart-car" v-if="current_tab == 1">
            <el-tabs v-model="curCar_tab" type="card" @tab-click="switchCar">
              <el-tab-pane label="人员速查" name="1">
                <quick-employee ref="QuickEmployee"></quick-employee>
              </el-tab-pane>

              <el-tab-pane label="车辆速查" name="2">
                <quick-car ref="QuickCar"></quick-car>
              </el-tab-pane>
            </el-tabs>
          </div>

          <div class="chart-grid" v-if="current_tab == 2">
            <el-tabs v-model="curGird_tab" type="card" @tab-click="switchGrid">
              <el-tab-pane label="部门责任网格" name="1">
                <grid-department ref="GirdDepDom" />
              </el-tab-pane>

              <el-tab-pane label="班组责任网格" name="2">
                <grid-team ref="GridTeamDom"></grid-team>
              </el-tab-pane>

              <el-tab-pane label="岗位责任网格" name="3">
                <grid-position ref="GridPosiDom"></grid-position>
              </el-tab-pane>
            </el-tabs>
          </div>

          <chart-train
            v-if="current_tab == 3"
            ref="chartTrainDom"
          ></chart-train>
        </div>
      </div>
    </div>

    <div class="home-right">
      <!-- 天气 -->
      <div class="weather-container" :class="judgeWeather()">
        <div class="weather-content">
          <div class="cur-temperature" v-if="weatherInfo.temperature">
            {{ weatherInfo.temperature }}°
          </div>

          <div class="cur-date">{{ weatherCurDate }}</div>
        </div>

        <div class="weather-situation text-clamp1">
          {{ weatherInfo.weather }}
        </div>
      </div>

      <!-- 公告 -->
      <div class="notice">
        <div class="notice-header">
          <div class="notice-title">公告</div>

          <div
            class="notice-header__btn"
            @click="$router.push({ name: 'notice' })"
          >
            查看更多
          </div>
        </div>

        <div class="notice-body">
          <ul class="notice-list">
            <li
              class="notice-item"
              v-for="(item, index) in noticeList"
              :key="index"
              @click="openNoticeDetail(item)"
            >
              <div
                class="item-label notification"
                :class="
                  item.type == '0' ? 'label-notice' : 'label-notification'
                "
              >
                {{ ["通知", "通报"][item.type] }}
              </div>

              <a class="item-content text-clamp1">
                {{ item.title }}
              </a>
            </li>
          </ul>
        </div>
      </div>

      <!-- 部门班组情况 -->
      <div class="situation">
        <div class="situation-header">
          <div class="situation-title">部门班组情况</div>

          <div class="situation-header-rigtht"></div>
        </div>

        <!-- <div class="situation-notice">
          <div class="situation-notice-content">
            <img
              class="notice-icon"
              src="@/assets/imgs/home/warning-icon.png"
            />
            <div class="notice-text">您有10条待处理信息</div>
          </div>

          <img class="delete-icon" src="@/assets/imgs/home/notice-delete.png" />
        </div> -->

        <ul class="situation-list">
          <li class="situation-item data-border">
            <div class="item-number data-number">
              {{ dataInfo.departmentEmployeeNum }}
            </div>

            <div class="item-label">员工数</div>
          </li>

          <li class="situation-item data-border">
            <div class="item-number data-number">
              {{ dataInfo.departmentCarNum }}
            </div>

            <div class="item-label">车辆数</div>
          </li>

          <li class="situation-item data-border">
            <div class="item-number data-number">
              {{ dataInfo.departmentDangerNum }}
            </div>

            <div class="item-label">风险数</div>
          </li>

          <li class="situation-item data-border">
            <div class="item-number data-number">
              {{ dataInfo.departmentHiddenNum }}
            </div>

            <div class="item-label">隐患数</div>
          </li>
        </ul>

        <div class="other-title">近一个月</div>

        <div div class="other-content">
          <div class="content-left data-border">
            <img class="shigu-icon" src="@/assets/imgs/home/shigu-icon.png" />

            <div class="shigu-content">
              <div class="shigu-number data-number">
                {{ dataInfo.departmentAccidentNum }}
              </div>
              <div class="shigu-label data-number">事故数</div>
            </div>
          </div>

          <div class="content-right">
            <div class="weifa-data data-border">
              <div class="weifa-number data-number">
                {{ dataInfo.departmentAccidentEmployeeNum }}
              </div>
              <div class="weifa-label">人员事故数</div>
            </div>

            <div class="warning-data data-border">
              <div class="warning-number data-number">
                {{ dataInfo.departmentAccidentCarNum }}
              </div>
              <div class="warning-label">其他事故数</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <notice-detail ref="NoticeDetDom" />

    <!-- 自定义组件 -->
    <template>
      <el-dialog
        :visible.sync="tip_visible"
        title="待办项"
        @close="tipPopupClose"
      >
        <div class="tip-content">
          <div class="tip-list">
            <div class="tip-item" v-for="(item, index) in tipList" :key="index">
              {{ item }}
            </div>
          </div>
        </div>
        <template slot="footer">
          <el-button type="primary" @click="tip_visible = false"
            >确认</el-button
          >
        </template>
      </el-dialog>
    </template>
  </div>
</template>

<script>
// import ChartGrid from "./component/grid";
import ChartTrain from "./component/train.vue";
import NoticeDetail from "./component/notice-detail";

import GridDepartment from "./component/gridDepartment";
import GridPosition from "./component/gridPosition";
import GridTeam from "./component/gridTeam";

import QuickEmployee from "./component/employee";
import QuickCar from "./component/car";

import axios from "axios";

export default {
  components: {
    ChartTrain,
    NoticeDetail,

    GridDepartment,
    GridPosition,
    GridTeam,

    QuickEmployee,
    QuickCar,
  },
  data() {
    return {
      tip_visible: false, // 提示弹窗显示状态
      curGird_tab: "1", // 当前网格类型
      curCar_tab: "1", // 当前人车类型
      tabList: [
        { label: "人车速查", val: 1 },
        { label: "责任网格", val: 2 },
        // { label: "培训情况", val: 3 },
      ],
      current_tab: 2,
      examList: [],
      dataInfo: "",
      weatherInfo: "", // 天气情况

      noticeList: [], // 公告列表
      curClientWidth: "", // 当前屏幕宽度
    };
  },
  computed: {
    weatherCurDate() {
      let date = new Date();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let week = date.getDay();

      let week_string = ["七", "一", "二", "三", "四", "五", "六"];

      return `星期${week_string[week]}，${month > 9 ? month : "0" + month}/${
        day > 9 ? day : "0" + day
      }`;
    },
    tipList() {
      return this.$store.state.tipList || null;
    },
  },
  created() {
    // if (this.tipList && this.tipList.length > 0) {
    //   this.tip_visible = true;
    // }

    this.getWeather();
    this.getIndexData();
  },
  mounted() {
    this.curClientWidth = document.body.clientWidth;

    window.addEventListener("resize", () => {
      this.curClientWidth = document.body.clientWidth;
    });
  },
  methods: {
    // 打开公告详情
    openNoticeDetail(obj) {
      this.$refs["NoticeDetDom"].init(obj);
    },
    // 判断当前天气
    judgeWeather() {
      let className = "";

      if (this.weatherInfo.weather) {
        if (this.weatherInfo.weather.indexOf("雨") != -1) {
          className = "rain-box";
        } else if (this.weatherInfo.weather.indexOf("雪") != -1) {
          className = "snow-box";
        } else if (
          this.weatherInfo.weather.indexOf("云") != -1 ||
          this.weatherInfo.weather.indexOf("雾") != -1
        ) {
          className = "cloudy-box";
        } else {
          className = "suny-box";
        }
      } else {
        className = "suny-box";
      }

      return className;
    },
    // 获取当前城市的天气
    getWeather() {
      axios({
        method: "get",
        url: "https://restapi.amap.com/v3/weather/weatherInfo",
        params: {
          key: "6a16103484e1de9631ef89ee1c722f9e",
          city: 110101,
        },
      }).then((res) => {
        if (res.status == 200) {
          if (res.data.status == 1) {
            this.weatherInfo = res.data.lives[0];
          }
        }
      });
    },

    // 切换网格类型
    switchGrid() {
      this.judgeComponent();
    },
    // 切换人车类型
    switchCar() {
      this.judgeComponent();
    },

    // 点击图例标签页
    clickTab(obj) {
      this.current_tab = obj.val;
      this.$nextTick(() => {
        this.judgeComponent();
      });
    },

    // 判断当前组件类型（人员速查）
    judgeQueckType() {},

    // 判断当前组件并初始化组件
    judgeComponent() {
      if (this.current_tab == 1) {
        if (this.curCar_tab == 1) {
          this.$refs["QuickEmployee"].init();
        } else if (this.curCar_tab == 2) {
          this.$refs["QuickCar"].init();
        }
      } else if (this.current_tab == 2) {
        if (this.curGird_tab == 1) {
          this.$refs["GirdDepDom"].init();
        } else if (this.curGird_tab == 2) {
          this.$refs["GridTeamDom"].init();
        } else if (this.curGird_tab == 3) {
          this.$refs["GridPosiDom"].init();
        }
      } else if (this.current_tab == 3) {
        this.$refs["chartTrainDom"].init(
          this.examList,
          this.dataInfo.jobEmployeeNum
        );
      }
    },

    // 获取首页数据
    getIndexData() {
      this.$http.indexData
        .info()
        .then((res) => {
          if (res.data) {
            this.dataInfo = res.data;
            this.examList = res.data.examList;
            this.noticeList = res.data.noticeList || [];

            this.judgeComponent();

            // this.xingshiCardInit({
            //   title: "行驶证",
            //   xszygqNum: res.data.xszygqNum,
            //   xszlgqNum: res.data.xszlgqNum,
            // });

            // this.jiashiCardInit({
            //   title: "道路运输证",
            //   dlyszygqNum: res.data.dlyszygqNum,
            //   dlyszlgqNum: res.data.dlyszlgqNum,
            // });

            // this.zigeCardInit({
            //   title: "资格证",
            //   zgzygqNum: res.data.zgzygqNum,
            //   zgzlgqNum: res.data.zgzlgqNum,
            // });

            // this.educationInit(this.examList);
            // this.fengxianDataInit({
            //   equipmentDangerNum: res.data.equipmentDangerNum,
            //   placeDangerNum: res.data.placeDangerNum,
            //   taskDangerNum: res.data.taskDangerNum,
            // });
          }
        })
        .catch(() => {});
    },

    // 确认待办项
    tipPopupClose() {
      this.$store.commit("saveTipList", null);
      console.log(this.$store.state.tipList, "tipList");
    },
  },
};
</script>

<style lang="scss" scoped>
.page-home {
  display: flex;
  justify-content: center;
  .home-left {
    // display: flex;
    // flex-direction: column;
  }

  .home-right {
    width: 334px;
    margin-left: 28px;
  }
}

.topbar {
  background-color: #fff;
  padding: 20px 16px 15px;
  .topbar-header {
    display: flex;
    align-items: center;

    .topbar-header-icon {
      width: 25.5px;
      height: 25.5px;
    }
    .topbar-title {
      font-size: 18px;
      font-weight: 550;
      color: #1d2129;
      margin-left: 10px;
      .title-key {
        font-size: 24px;
        color: #009d86;
        margin: 0 5px;
      }
    }
  }

  .topbar-list {
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    transition: 1s;

    .topbar-item {
      padding: 10px;
      transition: 1s;

      .item-wrapper {
        width: 290px;
        height: 94px;
        padding: 0 20px;
        display: flex;
        align-items: center;

        .item-icon {
          width: 60px;
          height: 60px;
        }

        .item-content {
          margin-left: 12px;
          color: #1d2129;

          .item-title {
            font-size: 12px;
          }

          .item-data {
            margin-top: 12px;
            .data-number {
              font-size: 22px;
              font-weight: 550;
            }
            .data-unit {
              font-size: 12px;
              color: #4e5969;
              margin-left: 5px;
            }
          }
        }
      }
      .person {
        background-color: #effff1;
      }
      .car {
        background-color: #eff7fd;
      }
      .accident {
        background-color: #f3f3ff;
      }
      .certificates {
        background-color: #e8f3ff;
      }
    }

    .topbar-item:not(:last-child) {
      // margin-right: 32px;
      // background-color: red;
    }
  }
}

.my-chart__container {
  height: 600px;
  margin-top: 23px;
  background-color: #fff;
  padding: 15px;

  .my-chart__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .chart-title {
    }
    .chart-tabs {
      display: flex;
      .chart-tab {
        font-size: 14px;
        border-radius: 32px;
        width: 88px;
        height: 28px;
        line-height: 28px;
        text-align: center;
        cursor: pointer;
        transition: 0.2s;
      }
      .chart-tab:hover {
        color: #009d86;
        background-color: #f2f3f8;
      }
      .chart-tab:not(:first-child) {
        margin-left: 5px;
      }
      .tab-active {
        color: #009d86;
        background-color: #f2f3f8;
      }
      .tab-default {
        color: #4e5969;
      }
    }
  }
}

.weather-container {
  width: 334px;
  height: 138px;
  background-size: contain;
  background-repeat: no-repeat;
  vertical-align: bottom;
  display: flex;
  align-items: center;
  position: relative;

  .weather-content {
    color: #fff;
    padding: 0 20px;
    .cur-temperature {
      background-image: linear-gradient(
        to bottom,
        rgba($color: #fff, $alpha: 0.8),
        rgba($color: #fff, $alpha: 0.2)
      );
      -webkit-background-clip: text;
      color: transparent;
      font-size: 87px;
      height: 87px;
      line-height: 87px;
    }
    .cur-date {
      letter-spacing: 1px;
    }
  }
  .weather-situation {
    position: absolute;
    bottom: 17px;
    right: 30px;
    min-width: 100px;
    max-width: 120px;
    text-align: center;
    font-size: 17px;
    color: #ffffff;
  }
}
.rain-box {
  background-image: url("~@/assets/imgs/home/weather-rain.png");
}
.snow-box {
  background-image: url("~@/assets/imgs/home/weather-snow.png");
}
.suny-box {
  background-image: url("~@/assets/imgs/home/weather-suny.png");
}
.cloudy-box {
  background-image: url("~@/assets/imgs/home/weather-cloudy.png");
}

.notice {
  height: calc(312px - 40px);
  background-color: #fff;
  margin-top: 16px;
  padding: 20px;
  display: flex;
  flex-direction: column;

  .notice-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .notice-title {
      font-size: 16px;
      font-weight: 550;
      color: #000;
    }
    .notice-header__btn {
      font-size: 12px;
      color: #165dff;
      cursor: pointer;
    }
    .notice-header__btn:hover {
      opacity: 0.8;
    }
  }
  ::-webkit-scrollbar {
    width: 7px;
  }
  ::-webkit-scrollbar-track {
    /* 滚动条的滑轨背景颜色 */
    background-color: #cccccc;
  }
  ::-webkit-scrollbar-thumb {
    /* 滑块颜色 */
    background-color: #11bb8d;
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb:hover {
    /* 鼠标移到滑块上面的变色 */
    background-color: rgb(161, 160, 160);
  }
  // ::-webkit-scrollbar-button {
  //   /* 滑轨两头的监听按钮颜色 */
  //   background-color: #fff;
  // }
  // ::-webkit-scrollbar-corner {
  //   /* 横向滚动条和纵向滚动条相交处尖角的颜色 */
  //   background-color: #fff;
  // }
  .notice-body {
    overflow-y: auto;
    margin-top: 15px;

    .notice-list {
      .notice-item {
        display: flex;
        align-items: center;
        cursor: pointer;
        height: 25px;
        line-height: 25px;
        font-size: 14px;

        .item-label {
          padding: 0 5px;
          border-radius: 2px;
        }
        .item-content {
          color: #4e5969;
          width: 0;
          flex-grow: 1;
          margin-left: 10px;
        }

        .label-notification {
          color: #ff7d00;
          background-color: #fff7e8;
        }
        .label-notice {
          color: #165dff;
          background-color: #e8f3ff;
        }
      }
      .notice-item:not(:first-child) {
        margin-top: 10px;
      }
      .notice-item:hover {
        .item-content {
          color: #11bb8d;
          text-decoration: underline;
        }
      }
    }
  }
}

.situation {
  background-color: #fff;
  margin-top: 16px;
  padding: 20px;

  .situation-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .situation-title {
      font-size: 16px;
      font-weight: 550;
      color: #000;
    }
  }

  .situation-notice {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    border-radius: 2px;
    background-color: #fcf6ec;
    padding: 10px 16px;

    .situation-notice-content {
      display: flex;
      align-items: center;
      .notice-icon {
        width: 20px;
        height: 20px;
      }
      .notice-text {
        font-size: 14px;
        color: #e6a23c;
        margin-left: 10px;
      }
    }

    .delete-icon {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      cursor: pointer;
      transition: 0.2s;
    }
    .delete-icon:hover {
      background-color: rgba(#000, 0.3);
    }
  }

  .situation-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 12px;
    .situation-item {
      width: calc(50% - 4px);
      flex-grow: 1;
      padding: 10px 15px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      .item-number {
      }
      .item-label {
        font-size: 12px;
        color: #8f9299;
        margin-bottom: 5px;
      }
    }
    .situation-item:nth-child(2n) {
      margin-left: 8px;
    }
    .situation-item:nth-child(n + 3) {
      margin-top: 10px;
    }
  }
}

.other-title {
  border-top: 1px solid #f2f3f5;
  padding-top: 20px;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 550;
  color: #000;
}

.other-content {
  height: 104px;
  display: flex;
  margin-top: 16px;

  .content-left {
    width: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .shigu-icon {
      width: 54px;
      height: 54px;
    }

    .shigu-content {
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: center;

      .shigu-number {
        font-size: 24px;
        font-weight: 550;
        color: #606266;
      }
      .shigu-label {
        height: fit-content;
        margin-left: 10px;
        font-size: 12px;
        color: #8f9299;
        margin-bottom: 5px;
      }
    }
  }

  .content-right {
    width: 0;
    flex-grow: 1;
    margin-left: 8px;
    display: flex;
    flex-direction: column;

    .weifa-data {
      height: 0;
      flex-grow: 1;
      padding: 0 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .weifa-number {
      }
      .weifa-label {
        text-align: right;
        width: 40px;
        font-size: 12px;
        color: #8f9299;
      }
    }
    .warning-data {
      height: 0;
      flex-grow: 1;
      padding: 0 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 8px;
      .warning-number {
      }
      .warning-label {
        text-align: right;
        width: 40px;
        font-size: 12px;
        color: #8f9299;
      }
    }
  }
}

.data-border {
  border: 1px solid #e5e8ef;
  border-radius: 4px;
}
.data-number {
  font-size: 24px;
  font-weight: 550;
  color: #606266;
}

:deep(.el-table__fixed-right) {
  height: 100% !important;
}
</style>